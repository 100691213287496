var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('router-link', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "to": {
        name: 'trust-inheritance.property.immoveable',
        query: {
          hibah_id: _vm.hibahId
        }
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/left-arrow.png",
      "height": "20",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "m-0",
    staticStyle: {
      "padding-left": "10px",
      "padding-top": "2px",
      "display": "inline-block"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("back-to")) + " " + _vm._s(_vm.$t("immovable-prop")) + " ")])])], 1), _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2 mt-3"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("property-information")))]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.category.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "home_phone_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("category")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.category.$model,
      expression: "$v.form.category.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.category, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("- " + _vm._s(_vm.$t("choose.category")) + " -")]), _c('option', {
    attrs: {
      "value": "Sebidang Tanah"
    }
  }, [_vm._v(_vm._s(_vm.$t("piece-of-land")))]), _c('option', {
    attrs: {
      "value": "Sebuah Bangunan"
    }
  }, [_vm._v(_vm._s(_vm.$t("a-building")))]), _c('option', {
    attrs: {
      "value": "Hakmilik Strata"
    }
  }, [_vm._v(_vm._s(_vm.$t("strata-ownership")))])]), _vm.$v.form.category.$error && !_vm.$v.form.category.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("category")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.property_type.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "home_phone_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("property-type")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.property_type.$model,
      expression: "$v.form.property_type.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.property_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.category")) + " --")]), _c('option', {
    attrs: {
      "value": "Geran (Geran)"
    }
  }, [_vm._v(_vm._s(_vm.$t("grant")) + " (Geran)")]), _c('option', {
    attrs: {
      "value": "Pajakan Negeri (PN)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("state-lease")) + " (PN) ")]), _c('option', {
    attrs: {
      "value": "Geran Mukim (GM)"
    }
  }, [_vm._v(_vm._s(_vm.$t("subdistrict-grant")) + " (GM)")]), _c('option', {
    attrs: {
      "value": "Pajakan Mukim (PM)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("subdistrict-lease")) + " (PM) ")]), _c('option', {
    attrs: {
      "value": "Hakmilik Sementara Daerah (PSD)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("district-temp-ownership")) + " (PSD) ")]), _c('option', {
    attrs: {
      "value": "Hakmilik Sementara Mukim (PSM)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("subdistrict-temp-ownership")) + " (PSM) ")])]), _vm.$v.form.property_type.$error && !_vm.$v.form.property_type.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("property-type")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.property_no.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("property-no")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.property_no.$model,
      expression: "$v.form.property_no.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.property_no.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.property_no, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.property_no.$error && !_vm.$v.form.property_no.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("property-no")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.lot_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("lot-no")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.lot_number.$model,
      expression: "$v.form.lot_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.lot_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.lot_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.lot_number.$error && !_vm.$v.form.lot_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("lot-no")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.city_type.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("city")) + " / " + _vm._s(_vm.$t("town")) + " / " + _vm._s(_vm.$t("subdistrict")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.city_type.$model,
      expression: "$v.form.city_type.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.city_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "Bandar"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('option', {
    attrs: {
      "value": "Pekan"
    }
  }, [_vm._v(_vm._s(_vm.$t("town")))]), _c('option', {
    attrs: {
      "value": "Mukim"
    }
  }, [_vm._v(_vm._s(_vm.$t("subdistrict")))])]), _vm.$v.form.city_type.$error && !_vm.$v.form.city_type.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.city-others")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.city.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("city")) + " / " + _vm._s(_vm.$t("town")) + " / " + _vm._s(_vm.$t("subdistrict")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.city.$model,
      expression: "$v.form.city.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('please-state')
    },
    domProps: {
      "value": _vm.$v.form.city.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.city, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.city.$error && !_vm.$v.form.city.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.city-others")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.district.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("district")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.district.$model,
      expression: "$v.form.district.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.district.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.district, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.district.$error && !_vm.$v.form.district.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("district")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.state.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.state.$model,
      expression: "$v.form.state.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.state.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.state, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.state.$error && !_vm.$v.form.state.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("state")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.address.$model,
      expression: "$v.form.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "rows": "2"
    },
    domProps: {
      "value": _vm.$v.form.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.address.$error && !_vm.$v.form.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.property_value.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("property-value")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('div', {
    staticClass: "input-group mb-3"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.property_value.$model,
      expression: "$v.form.property_value.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.property_value.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.property_value, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.form.property_value.$error && !_vm.$v.form.property_value.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("property-value")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
}]

export { render, staticRenderFns }